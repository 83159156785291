<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <footer class="bg-blue-700">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div class="space-y-8 lg:col-span-1">
          <p class="text-blue-200 pa-2 text-sm">
            Maibaum Liefer- und Aufstell-Service mit Herz und Schmuck für Bonn
            und Umgebung. Einfach online bestellen und an die Wunschadresse
            liefern lassen.
          </p>
          <p class="text-blue-200 pa-2 text-sm">
            Bei Fragen oder für individuelle Bestellungen kannst du dich auch
            via
            <a
              href="https://wa.me/4915788763156"
              class="text-white hover:text-orange"
              >WhatsApp</a
            >
            oder telefonisch bei uns melden:<br />
            <a href="tel:+4915788763156" class="text-white hover:text-orange"
              >+49 1578 876 3156</a
            >
          </p>
        </div>
        <div
          class="mt-12 sm:grid sm:grid-cols-2 gap-8 lg:mt-0 lg:col-span-2 lg:justify-items-end sm:px-6"
        >
          <div>
            <h3
              class="text-sm font-semibold text-blue-400 tracking-wider uppercase"
            >
              Links
            </h3>
            <ul role="list" class="mt-4 space-y-4">
              <li>
                <a
                  href="https://goo.gl/maps/27ZxGzCGiatj2B427"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="text-base text-gray-200 hover:text-gray-100"
                  >Abholort in Google Maps öffnen</a
                >
              </li>
              <li>
                <a
                  href="https://wa.me/4915788763156"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="text-base text-gray-200 hover:text-gray-100"
                  >Chat mit uns auf WhatsApp</a
                >
              </li>
              <li v-for="item in navigation.group" :key="item.name">
                <a
                  :href="item.href"
                  class="text-base text-gray-200 hover:text-gray-100"
                >
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </div>
          <div class="mt-12 sm:mt-0">
            <h3
              class="text-sm font-semibold text-blue-400 tracking-wider uppercase"
            >
              Rechtliches
            </h3>
            <ul role="list" class="mt-4 space-y-4">
              <li v-for="item in navigation.legal" :key="item.name">
                <router-link
                  :to="item.href"
                  class="text-base text-gray-200 hover:text-gray-100"
                >
                  {{ item.name }}
                </router-link>
              </li>
              <li>
                <button
                  class="text-base text-gray-200 hover:text-gray-100 cursor-pointer"
                  @click="showCookieBanner"
                >
                  Cookie-Hinweis anzeigen
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t-2 border-blue-200 pt-8">
        <p class="text-base text-blue-200 lg:text-center">
          {{ new Date().getFullYear() }} - maibaum-bonn.com
        </p>
        <p class="mt-4 text-base lg:text-center">
          <span class="text-blue-200">Webdesign:</span>
          <a
            href="https://kh-biotechnology.com"
            class="ml-2 text-indigo-200 hover:text-indigo-400"
            >KH Biotechnology</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useStore } from "vuex";

const navigation = {
  group: [
    { name: "Unsere Angebote", href: "/#pakete" },
    { name: "FAQ", href: "/#faq" },
  ],
  legal: [
    { name: "Impressum", href: "/impressum" },
    { name: "Datenschutzerklärung", href: "/datenschutz" },
    { name: "Allgemeine Geschäftsbedingungen", href: "/bedingungen" },
    { name: "Widerrufsbelehrung", href: "/widerrufsbelehrung" },
  ],
};

const store = useStore();
const showCookieBanner = () => store.commit("setCookieMessageHidden", false);
</script>
