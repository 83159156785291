<template>
  <div class="min-h-screen bg-gray-50">
    <the-navbar></the-navbar>
    <router-view></router-view>
  </div>
  <the-footer></the-footer>
  <notification></notification>
  <cookie-banner></cookie-banner>
  <img
    height="1"
    width="1"
    style="display: none"
    src="https://www.facebook.com/tr?id=1047299562805601&ev=PageView&noscript=1"
    v-if="pixelEnabled"
  />
</template>

<script setup>
import { computed, watch } from "vue";
import TheNavbar from "./views/components/TheNavbar.vue";
import TheFooter from "./views/components/TheFooter.vue";
import Notification from "./views/components/Notification.vue";
import CookieBanner from "./views/components/CookieBanner.vue";
import { useStore } from "vuex";
// import { loadScript } from "vue-plugin-load-script"

const store = useStore();
const pixelEnabled = computed(() => store.getters.trackingEnabled);

watch(
  () => store.getters.trackingEnabled,
  (trackingEnabled) => {
    if (trackingEnabled) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      // eslint-disable-next-line no-undef
      fbq("init", "1047299562805601");
      // eslint-disable-next-line no-undef
      fbq("track", "PageView");
      // loadScript(`https://connect.facebook.net/en_US/fbevents.js`)
      //   .then(() => {
      //     // eslint-disable-next-line no-undef
      //     if(!(typeof fbq === 'undefined' || fbq === null)) {
      //       // eslint-disable-next-line no-undef
      //       fbq('init', '1047299562805601');
      //       // eslint-disable-next-line no-undef
      //       fbq('track', 'PageView');
      //     }
      //   })
    }
  },
  {
    immediate: true,
  }
);
</script>
