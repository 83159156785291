import { ApiResponseSuccess } from "@/store/data/types/apiResponseSuccess";

export const apiResponseSuccess: ApiResponseSuccess = {
  ok: true,
  data: {
    success: true,
    alert: true,
    message: "Anfrage erfolgreich bearbeitet.",
  },
};
