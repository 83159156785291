import axios from "axios";
import mockApi from "./mockApi";
import store from "@/store/index";

const AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const checkApiAlerts = async (data: any) => {
  // check if we have to display any errors or success messages
  if (data.alert || (data.data && data.data.alert)) {
    if (data.error && data.message) {
      store.commit("addError", data.message);
    } else if (data.data && data.data.error && data.data.message) {
      store.commit("addError", data.data.message);
    } else if (data.success && data.message) {
      store.commit("addSuccess", data.message);
    } else if (data.data && data.data.success && data.data.message) {
      store.commit("addSuccess", data.data.message);
    } else if (data.errors) {
      data.errors.forEach((error: any) => {
        store.commit("addError", error.message);
      });
    } else if (data.data && data.data.errors) {
      data.data.errors.forEach((error: any) => {
        store.commit("addError", error.message);
      });
    }
  }
};

const alertConnectionErrorToUser = false;

// Automatically log / display errors
AxiosInstance.interceptors.response.use(
  (response) => {
    checkApiAlerts(response.data);
    return response;
  },
  (error) => {
    if (error.response) {
      checkApiAlerts(error.response.data);
    }

    if (alertConnectionErrorToUser) {
      store.commit("addError", "Ein Netzwerkfehler ist aufgetreten.");
    } else {
      console.log("Connection error.");
      console.log(error);
    }

    return Promise.reject(error);
  }
);

const baseApi =
  parseInt(process.env.VUE_APP_API_LOCAL, 10) >= 1 ? mockApi : AxiosInstance;

export default baseApi;
