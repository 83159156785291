<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-blue-800" id="navbar">
    <Disclosure as="nav" v-slot="{ open }">
      <div class="max-w-7xl mx-auto px-2 py-2 lg:py-4 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
          <div class="absolute inset-y-0 right-0 flex items-center md:hidden">
            <DisclosureButton
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-700 bg-gray-200 hover:text-white hover:bg-orange focus:outline-none"
            >
              <span class="sr-only">Navigatiosnmenü öffnen</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div
            class="flex-1 flex items-center justify-center md:justify-between md:items-stretch lg:h-12"
          >
            <div class="flex-shrink-0 flex items-center">
              <router-link to="/" class="d-block">
                <h1 class="text-xl font-bold text-white lg:text-3xl">
                  maibaum-bonn.com
                </h1>
              </router-link>
            </div>
            <div class="hidden md:block md:ml-6">
              <div class="flex space-x-6 items-center">
                <a
                  v-for="item in navigation"
                  :key="item.name"
                  :href="'/#' + item.href"
                  class="text-base font-medium text-white hover:text-blue-200"
                  >{{ item.name }}</a
                >
                <router-link
                  to="/#pakete"
                  class="whitespace-nowrap inline-flex items-center justify-center bg-blue-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:bg-orange"
                >
                  Paket auswählen
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <router-link
            v-for="item in navigationMenu"
            :key="item.name"
            :to="'/#' + item.href"
            class="text-white hover:text-blue-200 block px-3 py-2 rounded-md text-base font-medium"
            >{{ item.name }}</router-link
          >
          <router-link
            to="/#pakete"
            class="text-white hover:text-blue-200 block px-3 py-2 rounded-md text-base font-medium"
            >Paket auswählen</router-link
          >
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";

const navigation = [
  // { name: "Pakete", href: "pakete" },
  { name: "Bewertungen", href: "erfahrungen" },
  { name: "Bilder", href: "bilder" },
  { name: "FAQ", href: "faq" },
  { name: "Über Uns", href: "ueber" },
];

const navigationMenu = [
  // { name: "Pakete", href: "pakete" },
  { name: "Bewertungen", href: "erfahrungen" },
  { name: "Bilder", href: "bilder" },
  { name: "FAQ", href: "faq" },
  { name: "Über Uns", href: "ueber" },
];
</script>
