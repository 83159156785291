import { createRouter, createWebHistory } from "vue-router";
import { scrollTo } from "@/utils/scroll";

const Error404 = () => import("@/views/Error/Error404.vue");
const Home = () => import("@/views/Home.vue");
const Thanks = () => import("@/views/Thanks.vue");
const LegalImprint = () => import("@/views/Legal/LegalImprint.vue");
const LegalPrivacy = () => import("@/views/Legal/LegalPrivacy.vue");
const LegalRefund = () => import("@/views/Legal/LegalRefund.vue");
const LegalTerms = () => import("@/views/Legal/LegalTerms.vue");
const OrderAlternative = () => import("@/views/OrderAlternative.vue");

const routes = [
  {
    path: "/bestellung/:deliveryMethod/:productId",
    component: OrderAlternative,
    props: true,
  },
  { path: "/bestellung-alternativ", component: OrderAlternative },
  { path: "/", component: Home },
  { path: "/404", component: Error404 },
  { path: "/danke", component: Thanks },
  { path: "/impressum", component: LegalImprint },
  { path: "/datenschutz", component: LegalPrivacy },
  { path: "/bedingungen", component: LegalTerms },
  { path: "/widerrufsbelehrung", component: LegalRefund },
  { path: "/:pathMatch(.*)*", component: Error404 },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  if (!to.hash) {
    scrollTo("navbar");
  }
});

export default router;
