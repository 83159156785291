import { LegalState } from "@/store/data/types/legal";

export const legalBaseState: LegalState = {
  imprint: {
    title: "",
    description: "",
  },
  privacy: {
    title: "",
    description: "",
  },
  terms: {
    title: "",
    description: "",
  },
  refundTerms: {
    title: "",
    description: "",
  },
};
