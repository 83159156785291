import { createApp } from "vue";
import router from "@/router/index";
import store from "@/store/index";
import Markdown from "vue3-markdown-it";
// import VueGtag from "vue-gtag-next";
import App from "./App.vue";
import "windi.css";
import "./css/main.css";

createApp(App).use(router).use(store).use(Markdown).mount("#app");
// .use(VueGtag, {
//   property: {
//     id: "G-NT4VS5LTN9",
//     params: {
//       anonymize_ip: true,
//     },
//   },
//   isEnabled: store.getters.gTagEnabled,
// })
