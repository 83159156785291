export const scrollTo = (elementId: string) => {
  const targetElement = document.getElementById(elementId);

  if (targetElement) {
    window.scrollTo({
      top: targetElement.offsetTop,
      behavior: "smooth",
    });
  }
};
