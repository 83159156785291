import { getterTree, mutationTree, actionTree } from "typed-vuex";
import { legalBaseState } from "./data/initialization/legal";
import {
  ImprintState,
  LegalState,
  PrivacyState,
  TermsState,
} from "./data/types/legal";
import { apiLegal } from "@/store/api/apiCms";

export const state: LegalState = legalBaseState;

export const getters = getterTree(state, {
  imprint: (currentStateInstance): ImprintState | null =>
    currentStateInstance.imprint,
  privacy: (currentStateInstance): PrivacyState | null =>
    currentStateInstance.privacy,
  terms: (currentStateInstance): TermsState | null =>
    currentStateInstance.terms,
  refundTerms: (currentStateInstance): TermsState | null =>
    currentStateInstance.refundTerms,
});

export const mutations = mutationTree(state, {
  setImprintState(currentStateInstance: LegalState, payload: ImprintState) {
    currentStateInstance.imprint = payload;
  },
  setPrivacyState(currentStateInstance: LegalState, payload: PrivacyState) {
    currentStateInstance.privacy = payload;
  },
  setTermsState(currentStateInstance: LegalState, payload: TermsState) {
    currentStateInstance.terms = payload;
  },
  setRefundTermsState(currentStateInstance: LegalState, payload: TermsState) {
    currentStateInstance.refundTerms = payload;
  },
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    async fetchImprintState({ commit }) {
      const currentImprintState: ImprintState = await apiLegal.getImprint();
      commit("setImprintState", currentImprintState);
    },
    async fetchPrivacyState({ commit }) {
      const currentPrivacyState: PrivacyState = await apiLegal.getPrivacy();
      commit("setPrivacyState", currentPrivacyState);
    },
    async fetchTermsState({ commit }) {
      const currentTermsState: TermsState = await apiLegal.getTerms();
      commit("setTermsState", currentTermsState);
    },
    async fetchRefundTermsState({ commit }) {
      const currentRefundTermsState: TermsState =
        await apiLegal.getRefundTerms();
      commit("setRefundTermsState", currentRefundTermsState);
    },
  }
);

const legalModule = {
  state,
  getters,
  mutations,
  actions,
};

export default legalModule;
