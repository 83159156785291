import { DeliveryResponse } from "../types/deliveryResponse";

export const deliveryResponse: DeliveryResponse = {
  delivery_methods: {
    pickup: {
      description: "",
      sold_out: false,
      slots: [],
    },
    delivery: {
      description: "",
      sold_out: false,
    },
  },
};
