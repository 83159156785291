import { mockPromise } from "@/utils/mockPromise";
import { apiResponseSuccess } from "@/store/data/mock/apiResponseSuccess";
import { deliveryResponse } from "@/store/data/mock/deliveryResponse";
import { orderResponse } from "@/store/data/mock/orderResponse";
import { checkApiAlerts } from "./baseApi";

const mockApi = {
  get(path: string) {
    switch (path) {
      case "/delivery":
        checkApiAlerts(deliveryResponse);
        return mockPromise(deliveryResponse);
    }
  },
  post(path: string, payload: any) {
    switch (path) {
      case "/order":
        checkApiAlerts(orderResponse);
        return mockPromise(orderResponse);

      default:
        return mockPromise(apiResponseSuccess);
    }
  },
};

export default mockApi;
