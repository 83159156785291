import { ApiResponseData } from "../types/apiResponseData";
import { OrderResponse } from "../types/orderResponse";

export const orderResponse: ApiResponseData = {
  ok: true,
  data: {
    success: true,
    data: {
      order_id: "test",
    },
  },
};
