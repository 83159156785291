import {
  ImprintState,
  PrivacyState,
  TermsState,
} from "@/store/data/types/legal";

const mockImprintState: ImprintState = {
  title: "Impressum",
  description: `**Silas Kolb**
Hammstraße 40
53225 Bonn
Deutschland

Telefon: +49 1573 9324348

Mail: silaskolb@gmail.com

Kleinunternehmer gemäß § 19 UStG

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden: https://ec.europa.eu/consumers/odr/. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.

Impressum erstellt mit dem [Trusted Shops](https://legal.trustedshops.com/) Rechtstexter in Kooperation mit [FÖHLISCH Rechtsanwälte](https://foehlisch.com).`,
};

const mockPrivacyState: PrivacyState = {
  title: "Datenschutzerklärung",
  description: `Verantwortlicher für die Datenverarbeitung ist:
Silas Kolb
Hammstraße 40
53225 Bonn

silaskolb@gmail.com

Wir freuen uns über Ihr Interesse an unserem Online-Shop. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.


### 1. ZUGRIFFSDATEN UND HOSTING

Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert. Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.


1.1 HOSTING

Die Dienste zum Hosting und zur Darstellung der Webseite werden teilweise durch unsere Dienstleister im Rahmen einer Verarbeitung in unserem Auftrag erbracht. Soweit im Rahmen der vorliegenden Datenschutzerklärung nichts anderes erläutert wird, werden alle Zugriffsdaten sowie alle Daten, die in dafür vorgesehenen Formularen auf dieser Webseite erhoben werden, auf ihren Servern verarbeitet. Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.

Eine Datenübermittlung kann an ein Drittland/ an Drittländer, für die die Europäische Kommission kein angemessenes Datenschutzniveau festgestellt hat, aufgrund des Einsatzes von zusätzlichen Funktionen unseres Dienstleisters erfolgen. Ein angemessenes Datenschutzniveau wird dabei durch den Abschluss von Standardvertragsklauseln der Europäischen Kommission gewährleistet.

Unsere Dienstleister sitzen und/oder verwenden Server in diesen Ländern: USA
Für diese Länder liegt kein Angemessenheitsbeschluss der Europäischen Kommission vor. Unsere Zusammenarbeit mit Ihnen stützt sich auf diese Garantien: Standarddatenschutzklauseln der Europäischen Kommission, Standarddatenschutzklauseln einer Aufsichtsbehörde


1.2 CONTENT DELIVERY NETWORK

Zum Zwecke einer kürzeren Ladezeit setzen wir bei einigen Angeboten ein sogenanntes Content Delivery Network („CDN“) ein. Bei diesem Dienst werden Inhalte, z.B. große Mediendateien, über regional verteilte Server externer CDN-Dienstleister ausgeliefert. Daher werden auf den Servern der Dienstleister Zugriffsdaten verarbeitet. Unsere Dienstleister sind im Rahmen einer Auftragsverarbeitung für uns tätig. Unsere Dienstleister sitzen und/oder verwenden Server in Ländern außerhalb der EU und des EWR. Für diese Länder liegt kein Angemessenheitsbeschluss der Europäischen Kommission vor. Unsere Zusammenarbeit mit ihnen stützt sich auf Standarddatenschutzklauseln der Europäischen Kommission. Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.


### 2. DATENVERARBEITUNG ZUR VERTRAGSABWICKLUNG UND ZUR KONTAKTAUFNAHME


2.1 DATENVERARBEITUNG ZUR VERTRAGSABWICKLUNG

Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung oder bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) freiwillig mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme benötigen und Sie ohne deren Angabe die Bestellung nicht abschließen, bzw. die Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich.
Wir verwenden die von ihnen mitgeteilten Daten zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen (inkl. Anfragen zu und Abwicklung von ggf. bestehenden Gewährleistungs- und Leistungsstörungsansprüchen sowie etwaiger gesetzlicher Aktualisierungspflichten) gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO. Weitere Informationen zu der Verarbeitung Ihrer Daten, insbesondere zu der Weitergabe an unsere Dienstleister zum Zwecke der Bestellungs-, Zahlungs- und Versandabwicklung, finden Sie in den nachfolgenden Abschnitten dieser Datenschutzerklärung. Nach vollständiger Abwicklung des Vertrages werden Ihre Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf etwaiger steuer- und handelsrechtlichen Aufbewahrungsfristen gemäß Art. 6 Abs. 1 S. 1 lit. c DSGVO gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.


2.2 KONTAKTAUFNAHME

Im Rahmen der Kundenkommunikation erheben wir zur Bearbeitung Ihrer Anfragen gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO personenbezogene Daten, wenn Sie uns diese bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) freiwillig mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Bearbeitung Ihrer Kontaktaufnahme benötigen. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Nach vollständiger Bearbeitung Ihrer Anfrage werden Ihre Daten gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.


### 3. DATENVERARBEITUNG ZUM ZWECKE DER VERSANDABWICKLUNG

Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an den mit der Lieferung beauftragten Versanddienstleister weiter, soweit dies zur Lieferung bestellter Waren erforderlich ist.


### 4. DATENVERARBEITUNG ZUR ZAHLUNGSABWICKLUNG

Bei der Abwicklung von Zahlungen in unserem Online-Shop arbeiten wir mit diesen Partnern zusammen: technische Dienstleister, Kreditinstitute, Zahlungsdienstleister.


4.1 DATENVERARBEITUNG ZUR TRANSAKTIONSABWICKLUNG

Je nach ausgewählter Zahlungsart geben wir die für die Abwicklung der Zahlungstransaktion notwendigen Daten an unsere technischen Dienstleister, die im Rahmen einer Auftragsverarbeitung für uns tätig sind, oder an die beauftragten Kreditinstitute oder an den ausgewählten Zahlungsdienstleister weiter, soweit dies zur Abwicklung der Zahlung erforderlich ist. Dies dient der Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO. Zum Teil erheben die Zahlungsdienstleister die für die Abwicklung der Zahlung erforderlichen Daten selbst, z.B. auf ihrer eigenen Webseite oder über eine technische Einbindung im Bestellprozess. Es gilt insoweit die Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.
Bei Fragen zu unseren Partnern für die Zahlungsabwicklung und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.


4.2 DATENVERARBEITUNG ZUM ZWECKE DER BETRUGSPRÄVENTION UND DER OPTIMIERUNG UNSERER ZAHLUNGSPROZESSE

Gegebenenfalls geben wir unseren Dienstleistern weitere Daten, die sie zusammen mit den für die Abwicklung der Zahlung notwendigen Daten als unsere Auftragsverarbeiter zum Zwecke der Betrugsprävention und der Optimierung unserer Zahlungsprozesse (z.B. Rechnungsstellung, Abwicklung von angefochtenen Zahlungen, Unterstützung der Buchhaltung) verwenden. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an unserer Absicherung gegen Betrug bzw. an einem effizienten Zahlungsmanagement.


### 5. WERBUNG PER E-MAIL


5.1 E-MAIL-NEWSLETTER MIT ANMELDUNG UND NEWSLETTER-TRACKING

Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die hierfür erforderlichen oder gesondert von Ihnen mitgeteilten Daten, um Ihnen regelmäßig unseren E-Mail-Newsletter aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO zuzusenden. Die Abmeldung vom Newsletter ist jederzeit möglich und kann entweder durch eine Nachricht an die unten beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link im Newsletter erfolgen. Nach Abmeldung löschen wir Ihre E-Mail-Adresse aus der Empfängerliste, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.

Wir weisen Sie darauf hin, dass wir bei Versand des Newsletters Ihr Nutzerverhalten auswerten. Dazu analysieren wir auch Ihren Umgang mit unserem Newsletter durch Messung, Speicherung und Auswertung von Öffnungsraten und der Klickraten zum Zwecke der Gestaltung künftiger Newsletterkampagnen („Newsletter-Tracking“).

Für diese Auswertung beinhalten die versendeten E-Mails Ein-Pixel-Technologien (z.B. sog. Web-Beacons, Tracking-Pixel), die auf unserer Website gespeichert sind. Für die Auswertungen verknüpfen wir insbesondere die folgenden „Newsletterdaten“

 * die Seite, von der aus die Seite angefordert wurde (sog. Referrer-URL),
 * das Datum und die Uhrzeit des Aufrufs,
 * die Beschreibung des Typs des verwendeten Webbrowsers,
 * die IP-Adresse des anfragenden Rechners,
 * die E-Mail-Adresse,
 * das Datum und die Uhrzeit der Anmeldung und Bestätigung

und die Ein-Pixel-Technologien mit Ihrer E-Mail-Adresse oder Ihrer IP-Adresse und ggf. einer individuellen ID. Auch im Newsletter enthaltene Links können diese ID enthalten.

Wenn Sie kein Newsletter-Tracking wünschen, ist es jederzeit möglich sich - wie zuvor beschrieben - vom Newsletter abzumelden.

Die Informationen werden solange gespeichert, wie Sie den Newsletter abonniert haben.


5.2 NEWSLETTERVERSAND

Der Newsletter und das oben dargestellte Newsletter-Tracking wird gegebenenfalls auch durch unsere Dienstleister im Rahmen einer Verarbeitung in unserem Auftrag versendet. Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.

Unsere Dienstleister sitzen und/oder verwenden Server in folgenden Ländern, für die die Europäische Kommission durch Beschluss ein angemessenes Datenschutzniveau festgestellt hat: Vereinigtes Königreich

Unsere Dienstleister sitzen und/oder verwenden Server in diesen Ländern: USA
Für diese Länder liegt kein Angemessenheitsbeschluss der Europäischen Kommission vor. Unsere Zusammenarbeit mit Ihnen stützt sich auf diese Garantien: Standarddatenschutzklauseln der Europäischen Kommission, Standarddatenschutzklauseln einer Aufsichtsbehörde

Unsere Dienstleister sitzen und/oder verwenden Server in folgenden Ländern, für die die Europäische Kommission durch Beschluss ein angemessenes Datenschutzniveau festgestellt hat: Vereinigtes Königreich


### 6. COOKIES UND WEITERE TECHNOLOGIEN


 ALLGEMEINE INFORMATIONEN

Um den Besuch unserer Webseite attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen Seiten Technologien einschließlich sogenannter Cookies. Cookies sind kleine Textdateien, die automatisch auf Ihrem Endgerät gespeichert werden. Einige der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies).
Wir verwenden solche Technologien, die für die Nutzung bestimmter Funktionen unserer Webseite (z.B. Warenkorbfunktion) zwingend erforderlich sind. Durch diese Technologien werden IP-Adresse, Zeitpunkt des Besuchs, Geräte- und Browser-Informationen sowie Informationen zu Ihrer Nutzung unserer Webseite (z. B. Informationen zum Inhalt des Warenkorbs) erhoben und verarbeitet. Dies dient im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.

Zudem verwenden wir Technologien zur Erfüllung der rechtlichen Verpflichtungen, denen wir unterliegen (z.B. um Einwilligungen in die Verarbeitung Ihrer personenbezogenen Daten nachweisen zu können) sowie zu Webanalyse und Online-Marketing. Weitere Informationen hierzu einschließlich der jeweiligen Rechtsgrundlage für die Datenverarbeitung finden Sie in den nachfolgenden Abschnitten dieser Datenschutzerklärung.

Die Cookie-Einstellungen für Ihren Browser finden Sie unter den folgenden Links: Microsoft Edge™ [https://support.microsoft.com/de-de/help/4027947/microsoft-edge-delete-cookies] / Safari™ [https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14] / Chrome™ [https://support.google.com/chrome/answer/95647?hl=de&hlrm=en] / Firefox™ [https://support.mozilla.org/de/products/firefox/protect-your-privacy/cookies] / Opera™ [https://help.opera.com/de/latest/web-preferences/#cookies]

Soweit Sie in die Verwendung der Technologien gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben, können Sie Ihre Einwilligung jederzeit widerrufen durch eine Nachricht an die in der Datenschutzerklärung beschriebenen Kontaktmöglichkeit.


### 7. EINSATZ VON COOKIES UND ANDEREN TECHNOLOGIEN ZU WEBANALYSE UND WERBEZWECKEN

Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, verwenden wir auf unserer Webseite die nachfolgenden Cookies und andere Technologien von Drittanbietern. Nach Zweckfortfall und Ende des Einsatzes der jeweiligen Technologie durch uns werden die in diesem Zusammenhang erhobenen Daten gelöscht. Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Weitere Informationen zu Ihren Widerrufsmöglichkeiten finden Sie in dem Abschnitt "Cookies und weitere Technologien". Weitere Informationen einschließlich der Grundlage unserer Zusammenarbeit mit den einzelnen Anbietern finden Sie bei den einzelnen Technologien. Bei Fragen zu den Anbietern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.


 EINSATZ VON FACEBOOK-DIENSTEN ZU WEBANALYSE UND WERBEZWECKEN

 EINSATZ VON FACEBOOK PIXEL

Wir verwenden das Facebook Pixel im Rahmen der nachfolgend dargestellten Technologien der Meta Platforms Ireland Ltd. [http://de-de.facebook.com/facebookdublin/], 4 Grand Canal Square, Dublin 2, Irland („Facebook (by Meta)“ bzw. “Meta Platforms Ireland“). Mit dem Facebook Pixel werden Daten (IP-Adresse, Zeitpunkt des Besuchs, Geräte- und Browser-Informationen sowie Informationen zu Ihrer Nutzung unserer Webseite anhand von uns vorgegebenen Ereignissen wie z.B. Besuch einer Webseite oder Newsletteranmeldung) automatisch erhoben und gespeichert, aus denen unter Verwendung von Pseudonymen Nutzungsprofile erstellt werden. Im Rahmen des sog. erweiterten Datenabgleichs werden darüber hinaus Informationen zu Abgleichzwecken gehasht erhoben und gespeichert, mit denen Einzelpersonen identifiziert werden können (z.B. Namen, E-Mail-Adressen und Telefonnummern). Hierzu wird bei Besuch unserer Webseite durch das Facebook Pixel automatisch ein Cookie gesetzt, das automatisch mittels einer pseudonymen CookieID eine Wiedererkennung Ihres Browsers beim Besuch anderer Webseiten ermöglicht. Facebook (by Meta) wird diese Informationen mit weiteren Daten aus Ihrem Facebook-Konto zusammenführen und benutzen, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Webseitennutzung verbundene Dienstleistungen, insbesondere personalisierte und gruppenbasierte Werbung zu erbringen.
Die durch die Facebook (by Meta) Technologien automatisch erhobenen Informationen über Ihre Nutzung unserer Webseite werden in der Regel an einen Server der Meta Platforms, Inc., 1 Hacker Way, Menlo Park, California 94025, USA übertragen und dort gespeichert. Für die USA liegt kein Angemessenheitsbeschluss der Europäischen Kommission vor. Sofern die Datenübermittlung in die USA in unsere Verantwortlichkeit fällt, stützt sich unsere Kooperation auf Standarddatenschutzklauseln der Europäischen Kommission. Weitergehende Informationen über die Datenverarbeitung durch Facebook finden Sie in den Datenschutzhinweisen von Facebook (by Meta) [http://de-de.facebook.com/policy.php].


### 8. SOCIAL MEDIA


 UNSERE ONLINEPRÄSENZ AUF FACEBOOK (BY META), INSTAGRAM (BY META)

Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO gegenüber dem jeweiligen Social Media Betreiber erteilt haben, werden bei Besuch unserer Onlinepräsenzen auf den in der oben genannten sozialen Medien Ihre Daten für Marktforschungs- und Werbezwecke automatisch erhoben und gespeichert, aus denen unter Verwendung von Pseudonymen Nutzungsprofile erstellt werden. Diese können verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die mutmaßlich Ihren Interessen entsprechen. Hierzu werden im Regelfall Cookies eingesetzt. Die detaillierten Informationen zur Verarbeitung und Nutzung der Daten durch den jeweiligen Social Media Betreiber sowie eine Kontaktmöglichkeit und Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, entnehmen Sie bitte den unten verlinkten Datenschutzhinweisen der Anbieter. Sollten Sie diesbezüglich dennoch Hilfe benötigen, können Sie sich an uns wenden.

Facebook (by Meta) [http://www.facebook.com/about/privacy/] ist ein Angebot der Meta Platforms Ireland Ltd., 4 Grand Canal Square, Dublin 2, Irland („Meta Platforms Ireland“). Die durch Meta Platforms Ireland automatisch erhobenen Informationen über Ihre Nutzung unserer Online-Präsenz auf Facebook (by Meta) werden in der Regel an einen Server der Meta Platforms, Inc., 1 Hacker Way, Menlo Park, California 94025, USA übertragen und dort gespeichert. Für die USA liegt kein Angemessenheitsbeschluss der Europäischen Kommission vor. Unsere Zusammenarbeit mit ihnen stützt sich auf Standarddatenschutzklauseln der Europäischen Kommission. Die Datenverarbeitung im Rahmen des Besuchs einer Facebook (by Meta) Fanpage erfolgt auf Grundlage einer Vereinbarung zwischen gemeinsam Verantwortlichen gemäß Art. 26 DSGVO. Weitere Informationen (Informationen zu Insights-Daten) finden Sie hier [https://www.facebook.com/legal/terms/information_about_page_insights_data].

Instagram (by Meta) [http://help.instagram.com/519522125107875] ist ein Angebot der Meta Platforms Ireland Ltd., 4 Grand Canal Square, Dublin 2, Irland („Meta Platforms Ireland“) Die durch Meta Platforms Ireland automatisch erhobenen Informationen über Ihre Nutzung unserer Online-Präsenz auf Instagram werden in der Regel an einen Server der Meta Platforms, Inc., 1 Hacker Way, Menlo Park, California 94025, USA übertragen und dort gespeichert. Für die USA liegt kein Angemessenheitsbeschluss der Europäischen Kommission vor. Unsere Zusammenarbeit mit ihnen stützt sich auf Standarddatenschutzklauseln der Europäischen Kommission. Die Datenverarbeitung im Rahmen des Besuchs einer Instagram (by Meta) Fanpage erfolgt auf Grundlage einer Vereinbarung zwischen gemeinsam Verantwortlichen gemäß Art. 26 DSGVO. Weitere Informationen (Informationen zu Insights-Daten) finden Sie hier [https://www.facebook.com/legal/terms/information_about_page_insights_data].


### 9. KONTAKTMÖGLICHKEITEN UND IHRE RECHTE


9.1 IHRE RECHTE

Als Betroffener haben Sie folgende Rechte:

 * gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;
 * gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
 * gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung
   * zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
   * zur Erfüllung einer rechtlichen Verpflichtung;
   * aus Gründen des öffentlichen Interesses oder
   * zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
 * gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit
   * die Richtigkeit der Daten von Ihnen bestritten wird;
   * die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;
   * wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder
   * Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
 * gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
 * gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.

Widerspruchsrecht

Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu.

Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.

Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.


9.2 KONTAKTMÖGLICHKEITEN

Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Einschränkung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.


Datenschutzerklärung erstellt mit dem [Trusted Shops](https://legal.trustedshops.com/) Rechtstexter in Kooperation mit [FÖHLISCH Rechtsanwälte](https://foehlisch.com).`,
};

const mockTermsState: TermsState = {
  title: "Allgemeine Geschäftsbedingungen",
  description: `### 1. GELTUNGSBEREICH

Für alle Bestellungen über unseren Online-Shop gelten die nachfolgenden AGB. Unser Online-Shop richtet sich ausschließlich an Verbraucher.

Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.


### 2. VERTRAGSPARTNER, VERTRAGSSCHLUSS, KORREKTURMÖGLICHKEITEN

Der Kaufvertrag kommt zustande mit Silas Kolb.

Mit Einstellung der Produkte in den Online-Shop geben wir ein verbindliches Angebot zum Vertragsschluss über diese Artikel ab. Sie können unsere Produkte zunächst unverbindlich in den Warenkorb legen und Ihre Eingaben vor Absenden Ihrer verbindlichen Bestellung jederzeit korrigieren, indem Sie die hierfür im Bestellablauf vorgesehenen und erläuterten Korrekturhilfen nutzen. Der Vertrag kommt zustande, indem Sie durch Anklicken des Bestellbuttons das Angebot über die im Warenkorb enthaltenen Waren annehmen. Unmittelbar nach dem Absenden der Bestellung erhalten Sie noch einmal eine Bestätigung per E-Mail.


### 3. VERTRAGSSPRACHE, VERTRAGSTEXTSPEICHERUNG

Die für den Vertragsschluss zur Verfügung stehende(n) Sprache(n): Deutsch

Wir speichern den Vertragstext und senden Ihnen die Bestelldaten und unsere AGB in Textform zu. Den Vertragstext können Sie in unserem Kunden-Login einsehen.


### 4. LIEFERBEDINGUNGEN

Zuzüglich zu den angegebenen Produktpreisen können noch Versandkosten anfallen. Nähere Bestimmungen zu ggf. anfallenden Versandkosten erfahren Sie bei den Angeboten.

Sie haben grundsätzlich die Möglichkeit der Abholung bei Silas Kolb, Hammstraße 40, 53225 Bonn, Deutschland zu den nachfolgend angegebenen Geschäftszeiten: Am 30.04. zu der gebuchten Abholzeit.

Wir liefern nicht an Packstationen.


### 5. BEZAHLUNG

In unserem Shop stehen Ihnen grundsätzlich die folgenden Zahlungsarten zur Verfügung:

Vorkasse
Bei Auswahl der Zahlungsart Vorkasse nennen wir Ihnen unsere Bankverbindung in separater E-Mail und liefern die Ware nach Zahlungseingang.

PayPal, PayPal Express
Um den Rechnungsbetrag über den Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A, 22-24 Boulevard Royal, L-2449 Luxembourg („PayPal“) bezahlen zu können, müssen Sie bei PayPal registriert sein, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung bestätigen. Die Zahlungstransaktion wird durch PayPal unmittelbar nach Abgabe der Bestellung durchgeführt. Weitere Hinweise erhalten Sie im Bestellvorgang.

PayPal Plus
In Zusammenarbeit mit dem Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A, 22-24 Boulevard Royal, L-2449 Luxembourg („PayPal“) bieten wir Ihnen die nachfolgenden Zahlungsoptionen als PayPal Services an. Sofern nachfolgend nichts anderes geregelt ist, setzt die Zahlung über PayPal Plus keine Registrierung bei PayPal voraus. Weitere Hinweise erhalten Sie bei der jeweiligen Zahlungsoption und im Bestellvorgang.

PayPal
Um den Rechnungsbetrag über die Zahlungsoption PayPal bezahlen zu können, müssen Sie bei PayPal registriert sein, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung bestätigen. Die Zahlungstransaktion wird durch PayPal unmittelbar nach Abgabe der Bestellung durchgeführt.

Kreditkarte über PayPal
Ihre Karte wird durch PayPal nach Versendung der Ware belastet.

Lastschrift über PayPal
Die Zahlung per Lastschrift über PayPal setzt eine Adress- und Bonitätsprüfung voraus und erfolgt direkt an PayPal. Mit Bestätigung der Zahlungsanweisung erteilen Sie PayPal ein Lastschriftmandat. Über das Datum der Kontobelastung werden Sie von PayPal informiert (sog. Prenotification). Die Kontobelastung erfolgt vor Versendung der Ware.

Barzahlung bei Abholung
Sie zahlen den Rechnungsbetrag bei der Abholung bar.


### 6. EIGENTUMSVORBEHALT

Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.


### 7. TRANSPORTSCHÄDEN

Werden Waren mit offensichtlichen Transportschäden angeliefert, so reklamieren Sie solche Fehler bitte möglichst sofort beim Zusteller und nehmen Sie bitte unverzüglich Kontakt zu uns auf. Die Versäumung einer Reklamation oder Kontaktaufnahme hat für Ihre gesetzlichen Ansprüche und deren Durchsetzung, insbesondere Ihre Gewährleistungsrechte, keinerlei Konsequenzen. Sie helfen uns aber, unsere eigenen Ansprüche gegenüber dem Frachtführer bzw. der Transportversicherung geltend machen zu können.


### 8. GEWÄHRLEISTUNG UND GARANTIEN


8.1 MÄNGELHAFTUNGSRECHT

Es gilt das gesetzliche Mängelhaftungsrecht.


8.2 GARANTIEN UND KUNDENDIENST

Informationen zu gegebenenfalls geltenden zusätzlichen Garantien und deren genaue Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im Online-Shop.


### 9. HAFTUNG

Für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden, haften wir stets unbeschränkt

* bei Verletzung des Lebens, des Körpers oder der Gesundheit,
* bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung,
* bei Garantieversprechen, soweit vereinbart, oder
* soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist.

Bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf, (Kardinalpflichten) durch leichte Fahrlässigkeit von uns, unseren gesetzlichen Vertretern oder Erfüllungsgehilfen ist die Haftung der Höhe nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung typischerweise gerechnet werden muss.
Im Übrigen sind Ansprüche auf Schadensersatz ausgeschlossen.


### 10. STREITBEILEGUNG

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, diese finden Sie hier: https://ec.europa.eu/consumers/odr. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.

AGB erstellt mit dem [Trusted Shops](https://legal.trustedshops.com/) Rechtstexter in Kooperation mit [FÖHLISCH Rechtsanwälte](https://foehlisch.com).
`,
};

const mockRefundTermsState: TermsState = {
  title: "Widerrufsbelehrung",
  description: `Verbraucher haben ein vierzehntägiges Widerrufsrecht.

### Widerrufsrecht

Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.

Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Silas Kolb, Hammstraße 40, 53225 Bonn, Deutschland, silaskolb@gmail.com, Telefon: +4915739324348) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.

Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.

### Folgen des Widerrufs

Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.

Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren in Höhe von 80 Euro. EUR. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.

Das Widerrufsrecht besteht **nicht** bei den folgenden Verträgen:

* Verträge zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind.
* Verträge zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde.

Hierunter fällt die Lieferung von Maibäumen.

### Muster-Widerrufsformular

(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)

– An Silas Kolb, Hammstraße 40, 53225 Bonn, Deutschland, silaskolb@gmail.com

– Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden
Waren (*)/die Erbringung der folgenden Dienstleistung (*)

– Bestellt am (*)/erhalten am (*)

– Name des/der Verbraucher(s)

– Anschrift des/der Verbraucher(s)

– Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)

– Datum

(*) Unzutreffendes streichen.


Widerrufsbelehrung erstellt mit dem [Trusted Shops](https://legal.trustedshops.com/) Rechtstexter in Kooperation mit [FÖHLISCH Rechtsanwälte](https://foehlisch.com).
`,
};

export const mockImprintData = {
  ok: true,
  data: mockImprintState,
};

export const mockPrivacyData = {
  ok: true,
  data: mockPrivacyState,
};

export const mockTermsData = {
  ok: true,
  data: mockTermsState,
};

export const mockRefundTermsData = {
  ok: true,
  data: mockRefundTermsState,
};
