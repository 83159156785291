import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import deliveryModule from "./delivery";
import legalModule from "./legal";

const store = createStore({
  state() {
    return {
      notifications: [],
      cookieMessageHidden: false,
      trackingEnabled: false,
    };
  },
  getters: {
    trackingEnabled: (state: any) => state.trackingEnabled,
    notifications: (state: any) => state.notifications,
    cookieMessageHidden: (state: any) => state.cookieMessageHidden,
  },
  mutations: {
    addNotification: (state, payload) => {
      state.notifications.push(payload);
    },
    addError: (state, message) => {
      state.notifications.push({
        message,
        type: "error",
      });
    },
    addSuccess: (state, message) => {
      state.notifications.push({
        message,
        type: "success",
      });
    },
    popNotification: (state) => {
      if (state.notifications.length >= 2) {
        state.notifications = state.notifications.slice(0, -1);
      } else {
        state.notifications = [];
      }
    },
    setTrackingEnabled: (state, value) => {
      const previousTrackingEnabledState = state.trackingEnabled;
      state.trackingEnabled = value;
      if (previousTrackingEnabledState && !value) {
        window.location.reload();
      }
    },
    setCookieMessageHidden: (state, value) => {
      state.cookieMessageHidden = value;
    },
  },
  modules: {
    legal: legalModule,
    delivery: deliveryModule,
  },
  plugins: [createPersistedState()],
});

export default store;
