<template>
  <div v-if="!cookieMessageHidden">
    <div
      class="fixed bottom-0 top-0 left-0 right-0 w-full h-full bg-gray-900 opacity-70"
    ></div>
    <div class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
      <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="p-2 rounded-lg bg-blue-800 shadow-lg sm:p-3">
          <div class="flex items-center justify-between flex-wrap">
            <div class="w-full flex-1 flex items-center">
              <span class="flex p-2 rounded-lg bg-blue-600">
                <InformationCircleIcon
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <p class="ml-3 font-medium text-white">
                <span class="inline">
                  Wir verwenden Cookies sowie verschiedene Tracking-Lösungen von
                  Drittanbietern.
                  <router-link
                    to="/datenschutz"
                    class="text-blue-200 hover:text-blue-50"
                    >Datenschutzerklärung</router-link
                  >
                </span>
              </p>
            </div>
            <div class="order-2 mt-2 flex-shrink-0 w-full sm:mt-0 sm:w-auto">
              <button
                class="flex w-full sm:w-auto items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm font-medium text-blue-600 bg-white hover:bg-blue-50"
                @click="accept"
              >
                Akzeptieren
              </button>
            </div>
            <div
              class="order-3 flex-shrink-0 w-full sm:w-auto mt-2 sm:mt-0 sm:ml-2"
            >
              <button
                type="button"
                class="flex w-full sm:w-auto items-center justify-center px-2 py-1 border border-transparent rounded-md hover:bg-blue-500 text-white text-sm focus:outline-none focus:ring-2 focus:ring-white"
                @click="decline"
                title="Ausschließlich notwendige Cookies verwenden"
              >
                <span class="sr-only"
                  >Ausschließlich notwendige Cookies verwenden</span
                >
                <span>Nur notwendige</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { useStore } from "vuex";

const store = useStore();

const cookieMessageHidden = computed(() => store.state.cookieMessageHidden);

const accept = () => {
  store.commit("setTrackingEnabled", true);
  store.commit("setCookieMessageHidden", true);
};

const decline = () => {
  store.commit("setTrackingEnabled", false);
  store.commit("setCookieMessageHidden", true);
};
</script>
