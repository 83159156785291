import {
  ImprintState,
  PrivacyState,
  TermsState,
} from "@/store/data/types/legal";
import mockApiCms from "./mockApiCms";

export const apiLegal = {
  getImprint: async (): Promise<ImprintState> => {
    // @ts-ignore
    const data: { ok: boolean; data: ImprintState } = await mockApiCms.get(
      "/legal/imprint"
    );
    return data.data;
  },
  getPrivacy: async (): Promise<PrivacyState> => {
    // @ts-ignore
    const data: { ok: boolean; data: PrivacyState } = await mockApiCms.get(
      "/legal/privacy"
    );
    return data.data;
  },
  getTerms: async (): Promise<TermsState> => {
    // @ts-ignore
    const data: { ok: boolean; data: TermsState } = await mockApiCms.get(
      "/legal/terms"
    );
    return data.data;
  },
  getRefundTerms: async (): Promise<TermsState> => {
    // @ts-ignore
    const data: { ok: boolean; data: TermsState } = await mockApiCms.get(
      "/legal/refund"
    );
    return data.data;
  },
};
