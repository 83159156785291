import { ApiResponseData } from "../data/types/apiResponseData";
import { DeliveryResponse } from "../data/types/deliveryResponse";
import { OrderRequest } from "../data/types/orderRequest";
import baseApi from "./baseApi";

export const apiOrder = {
  post: async (payload: OrderRequest): Promise<ApiResponseData> => {
    // @ts-ignore
    const response: { data: ApiResponseData } = await baseApi.post("/order", {
      ...payload,
    });
    return response.data;
  },
  capture: async (orderId: string): Promise<ApiResponseData> => {
    // @ts-ignore
    const response: { data: ApiResponseData } = await baseApi.get(
      `/order/capture/${orderId}`
    );
    return response.data;
  },
};

export const apiDelivery = {
  get: async (): Promise<DeliveryResponse> => {
    // @ts-ignore
    const response: { data: ApiResponseData } = await baseApi.get(`/delivery`);
    const data: DeliveryResponse = response.data.data.data;
    console.log(data);
    return data;
  },
};
