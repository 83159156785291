import { mockPromise } from "@/utils/mockPromise";
import {
  mockImprintData,
  mockPrivacyData,
  mockTermsData,
  mockRefundTermsData,
} from "../data/mock/legal";

const mockApiCms = {
  get(path: string) {
    switch (path) {
      case "/legal/imprint":
        return mockPromise(mockImprintData);

      case "/legal/privacy":
        return mockPromise(mockPrivacyData);

      case "/legal/terms":
        return mockPromise(mockTermsData);

      case "/legal/refund":
        return mockPromise(mockRefundTermsData);

      default:
        return mockPromise(mockImprintData);
    }
  },
};

export default mockApiCms;
