import { getterTree, mutationTree, actionTree } from "typed-vuex";
import { deliveryResponse } from "@/store/data/mock/deliveryResponse";
import { apiDelivery } from "./api/api";
import { DeliveryResponse } from "./data/types/deliveryResponse";

export const state: { deliveryMethods: DeliveryResponse } = {
  deliveryMethods: deliveryResponse,
};

export const getters = getterTree(state, {
  deliveryMethods: (currentStateInstance) =>
    currentStateInstance.deliveryMethods,
});

export const mutations = mutationTree(state, {
  setState(
    currentStateInstance: { deliveryMethods: DeliveryResponse },
    payload: DeliveryResponse
  ) {
    currentStateInstance.deliveryMethods = payload;
  },
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    async fetchDeliveryState({ commit }) {
      // @ts-ignore
      const currentDeliveryResponse: DeliveryResponse = await apiDelivery.get();
      commit("setState", currentDeliveryResponse);
    },
  }
);

const deliveryModule = {
  state,
  getters,
  mutations,
  actions,
};

export default deliveryModule;
